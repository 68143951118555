import { createRouter, createWebHistory } from 'vue-router';
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () => import('../components/home.vue'),
            children: [
                {
                    path: '/',
                    component: () => import('../components/index/hot-index.vue')
                },
                {
                    path: '/contact_us',
                    component: () => import('../components/contact_us/contact_us.vue'),
                },
            ]
        },
        {
            path: '/404',
            name: '404',
            component:  () => import('../components/page404/page404.vue'),
        },
        {
            path: '/:pathMatch(.*)',
            redirect: '/404'
        }
    ]
})

export default router