import { createApp } from 'vue'
import '/public/style.css'
import App from './App.vue'
import router from './router/index'
import MetaInfo from 'vue-meta-info'
import '../node_modules/aos/dist/aos.css';
import '../public/sdk/tutordata.min'

var tutor = window["tutorDataAnalytic201505"];
tutor.init({
    server_url: "https://track.hotpotcn.com/ta?project=hotpot&token=bw9MV43PnURlFBGF",
    heatmap_url:"../public/sdk/heatmap.min.js",
    use_client_time: true,
    send_type: "ajax",
    heatmap: {},
    show_log: false
});

tutor.quick("autoTrack");

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);//返回页面顶部
})

const app = createApp(App);
app.use(router).use(MetaInfo).mount('#app');